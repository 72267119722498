import { getRewardText, getTotalPriceText } from "halifax";
import { FiatPrice, RewardsPrice } from "redmond";

export const getPriceAndRewardsCopy = ({
  price,
  rewards,
  separator,
}: {
  price: string;
  rewards?: string;
  separator?: string;
}) =>
  `<strong>${price}</strong>${
    rewards ? ` ${separator ?? "/"} ${rewards}` : ""
  }`;

export const getDiscountedPriceAndRewardsCopy = ({
  origPrice,
  discountedPrice,
  rewards,
}: {
  origPrice: string;
  discountedPrice: string;
  rewards?: string;
}) =>
  `<span class="cross-out">${origPrice}</span> <span class="green-text">${discountedPrice}${
    rewards ? ` / ${rewards}` : ""
  }</span>`;

export const getStepCopy = (step: number, ancillary: string) =>
  `Step ${step}: ${ancillary}`;

export const CFAR_SUBTITLE_COPY =
  "Add the flexibilty to cancel your flight for any reason.";

export const DISRUPTION_PROTECTION_SUBTITLE_COPY = (isSmokeTest: boolean) =>
  isSmokeTest
    ? "Relax knowing you’re ready with options if plans change."
    : "We’re here to help if you experience a flight disruption while traveling.";

export const CONTINUE_TO_CHECKOUT_COPY = "Continue to checkout";

export const BACK = "Back";

export const CONTINUE = "Continue";

export const AIR_OFFER_REDESIGN_CLASSNAME = "air-offer-redesign";

export const CORP_SUBSCRIPTION_CASH_AMOUNT = 10;

export const getCorpFintechSubscriptionPrices = (
  price: FiatPrice,
  rewards?: RewardsPrice
) => {
  const rewardsMultiplier = (rewards?.value || 0) / (price.value || 1);

  const discountedPrice = {
    ...price,
    value: Math.max(price.value - CORP_SUBSCRIPTION_CASH_AMOUNT, 0),
  };
  const discountedPriceString = getTotalPriceText({ price: discountedPrice });
  const totalPriceString = getTotalPriceText({ price });

  const rewardsDiscountedPrice = rewards
    ? {
        ...rewards,
        value: Math.max(
          rewards.value - CORP_SUBSCRIPTION_CASH_AMOUNT * rewardsMultiplier,
          0
        ),
      }
    : undefined;

  const rewardString = rewardsDiscountedPrice
    ? getRewardText({ reward: rewardsDiscountedPrice })
    : undefined;

  return {
    origPrice: totalPriceString,
    discountedPrice: discountedPriceString,
    reward: rewardString,
  };
};

export const getCorpFintechSubscriptionPriceCopy = (
  price: FiatPrice,
  rewards?: RewardsPrice
) => {
  const { origPrice, discountedPrice, reward } =
    getCorpFintechSubscriptionPrices(price, rewards);
  return getDiscountedPriceAndRewardsCopy({
    origPrice,
    discountedPrice,
    rewards: reward,
  });
};

export const CORP_FINTECH_SUBSCRIPTION_COPY = (
  price: FiatPrice,
  rewards?: RewardsPrice
) => {
  return `Added for ${getCorpFintechSubscriptionPriceCopy(
    price,
    rewards
  )} per traveler.`;
};

export const CORP_SUBSCRIPTION_CHIP_COLLAPSED = `Subscribe and save $30 on 3 bookings`;
